<template>
  <v-dialog v-model="dialogTable">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" :x-small="$vuetify.breakpoint.xs" dark v-bind="attrs" v-on="on">
        Открыть Дневник
      </v-btn>
    </template>
    <v-card v-if="mealsData">
      <v-card-actions>
        <v-data-table
          sort-desc
          :items-per-page="5"
          :headers="headers"
          :items="meals"
          sort-by="date"
          class="elevation-3"
        >
          <template v-slot:top>
            <v-toolbar color="blue lighten-4">
              <v-toolbar-title>Дневник питания</v-toolbar-title>
            </v-toolbar>
            <v-card-actions>
              <v-spacer />
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    + Прием пищи
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="form" v-model="valid">
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.date"
                                label="Дата приема пищи"
                                prepend-icon="mdi-calendar-multiple"
                                type="text"
                                :rules="dialogRules"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="editedItem.date"
                              min="1950-01-01"
                              @change="saveDate"
                            />
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.number"
                            :items="items"
                            menu-props="auto"
                            hide-details
                            label="Прием пищи №"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.hunger"
                            :items="items"
                            menu-props="auto"
                            hide-details
                            label="Чувство голода (десятибальная шкала)"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.emotions"
                            label="Эмоции перед едой"
                            :rules="dialogRules"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.products"
                            label="Продукты"
                            :rules="dialogRules"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.volume"
                            :items="weight"
                            menu-props="auto"
                            hide-details
                            label="Объем пищи (гр./мл.)"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.busy"
                            label="Занятие во время еды"
                            :rules="dialogRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedItem.satisfaction"
                            :items="items"
                            menu-props="auto"
                            hide-details
                            label="Удовлетворенность от еды (десятибальная шкала)"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      отменить
                    </v-btn>
                    <v-btn :disabled="!valid" color="blue darken-1" text @click="saveMeals">
                      сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
            </v-card-actions>

            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="text-h5"
                  >Вы уверены, что хотите удалить этот элемент?</v-card-title
                >
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">отменить</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">удалить</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-actions>

      <v-col class="pt-16">
        <sparklines :meals="meals" />
      </v-col>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialogTable = !dialogTable">
          закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'DialogTable',
  components: {
    Sparklines: () => import('@/components/studios/Sparklines')
  },
  props: {
    titleStudios: {
      type: String,
      required: true
    }
  },
  data: () => ({
    meals: [],
    valid: false,
    dialogTable: false,
    dialog: false,
    dialogDelete: false,
    menu: false,
    headers: [
      {
        text: 'Дата приема пищи',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Прием пищи №',
        align: 'center',
        sortable: false,
        value: 'number'
      },
      { text: 'Чувство голода (10-бальная шкала)', align: 'center', value: 'hunger' },
      { text: 'Эмоции перед едой', sortable: false, value: 'emotions' },
      { text: 'Продукты', sortable: false, value: 'products' },
      { text: 'Объем пищи (гр./мл.)', align: 'center', value: 'volume' },
      { text: 'Занятие во время еды', sortable: false, value: 'busy' },
      {
        text: 'Удовлетворенность от еды (10-бальная шкала)',
        align: 'center',
        value: 'satisfaction'
      },
      { text: 'Действия', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      date: new Date().toISOString().substr(0, 10),
      number: 0,
      hunger: 0,
      emotions: '',
      products: '',
      volume: 0,
      busy: '',
      satisfaction: 0
    },
    defaultItem: {
      date: new Date().toISOString().substr(0, 10),
      number: 0,
      hunger: 0,
      emotions: '',
      products: '',
      volume: 0,
      busy: '',
      satisfaction: 0
    },
    items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    weight: [
      0,
      50,
      100,
      150,
      200,
      250,
      300,
      350,
      400,
      450,
      500,
      550,
      600,
      650,
      700,
      750,
      800,
      850,
      900,
      950,
      1000
    ],
    dialogRules: [
      v => !!v || 'Введите данные',
      v => (v && v.length <= 2000) || 'Не более 2000 символов'
    ]
  }),

  computed: {
    ...mapState({
      mealsData: state => state.studios.meals
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'Новая запись' : 'Редактировать запись'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  created() {
    this.$store
      .dispatch('fetchTableStudio', {
        titleStudios: 'Sensory nutrition',
        typeExercise: 'studios'
      })
      .then(() => (this.meals = this.mealsData.map(item => ({ ...item }))))
  },

  methods: {
    saveMeals() {
      if (this.editedIndex > -1) {
        Object.assign(this.meals[this.editedIndex], this.editedItem)
      } else {
        this.meals.push(this.editedItem)
      }
      this.$store
        .dispatch('saveTableStudio', {
          meals: this.meals.map(item => ({ ...item })),
          titleStudios: 'Sensory nutrition',
          typeExercise: 'studios'
        })
        .then(() => this.close())
    },
    editItem(item) {
      this.editedIndex = this.meals.indexOf(item)
      this.editedItem = item
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.meals.indexOf(item)
      this.editedItem = item
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.meals.splice(this.editedIndex, 1)
      this.$store
        .dispatch('saveTableStudio', {
          meals: this.meals.map(item => ({ ...item })),
          titleStudios: 'Sensory nutrition',
          typeExercise: 'studios'
        })
        .then(() => this.closeDelete())
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    }
  }
}
</script>

<style scoped></style>
